import { ActionType } from 'deox';
import { uploadImage } from './actions';
import { API } from '../../api';
import { put, takeLatest } from 'redux-saga/effects';
import { UploadImageErrorType } from './types';

function* uploadImageSaga({ payload }: ActionType<typeof uploadImage.request>) {
  const { body, onSuccessCb, onFailCb } = payload;

  try {
    const { data } = yield API.uploadImage(body);

    onSuccessCb(data);

    yield put(uploadImage.success());
    //eslint-disable-next-line
  } catch (e: any) {
    const errorType = e.response?.data?.detail?.includes('Maximum upload size exceeded;')
      ? UploadImageErrorType.MAX_SIZE
      : UploadImageErrorType.DEFAULT;

    onFailCb(errorType);

    yield put(uploadImage.fail({ error: e.message }));
  }
}

export function* watchSettings() {
  yield takeLatest(uploadImage.request, uploadImageSaga);
}
