import { VariantType } from 'notistack';
import { LANGUAGES } from '../../constants/constants';
export type SettingsState = {
  appLanguage: LANGUAGES.DE | LANGUAGES.EN;
  uploadImageLoading: boolean;
  stackBar: {
    visible: boolean;
    message: string;
    variant?: VariantType;
  };
};

export enum UploadImageErrorType {
  MAX_SIZE = 'MAX_SIZE',
  DEFAULT = 'DEFAULT',
}
